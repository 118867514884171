import React from "react"
import styled from "@emotion/styled"
import { Button, Rate, Space, Typography } from "antd"
import Link from "next/link"
import { css } from "@emotion/react"
import { md, sm, xl } from "../../styles/mediaQuery"
import { CourseTechnology } from "../../lib/prismic/mapData"
import { H4 } from "../blog/common"
import Image from "next/legacy/image"
import { darken, desaturate, rgba } from "polished"
import { Theme } from "../../styles/theme"
import { theme as themeColorPalette } from "../../styles/styles"
import { SubCourseCard } from "./SubCourseCard"
import { MappedCourse } from "../../lib/prismic/MappedCourse"

export const getTopSpaceTextColorTheme = (technology: CourseTechnology, isOpen: boolean, theme?: Theme): string => {
  if (theme === Theme.dark) {
    return darken(0.2, technology.color)
  }

  if (!isOpen) {
    return desaturate(0.2, technology.color)
  }

  return technology.color
}

const removePattern = (text: string): string => text.replace(/\(.*?\)/g, "").trim()

export const CourseCard = ({
  course,
  technology,
  simpleLayout = false,
  theme,
  informationOnly = false,
  whenOnHeader = false,
  withSubCourse = true,
  dateAtHeader = false
}: CourseCardType) => {
  const textColor = theme === Theme.dark ? themeColorPalette.colors.dark.textWhite : "#fff"
  const color = getTopSpaceTextColorTheme(technology, !!course.isOpen, theme)

  return (
    <>
      <CourseCardWrapper theme={theme}>
        <Link href={course.registerUrl} title={`Přejít na ${removePattern(course.name)}`}>
          <TopSpace simpleLayout={simpleLayout} height={whenOnHeader ? "175px" : undefined}>
            <TopSpaceText color={color} bgOpacity={course.image ? 0.75 : 1}>
              {!whenOnHeader && (
                <Typography.Text
                  type="secondary"
                  style={{ color: textColor, fontSize: 11, textTransform: "uppercase" }}
                  css={css`
                    ${sm} {
                      display: none;
                    }
                  `}>
                  {course.descriptionType}
                </Typography.Text>
              )}

              {!whenOnHeader && dateAtHeader ? (
                <Flex>
                  <Typography.Title
                    level={3}
                    style={{ color: textColor, fontSize: whenOnHeader ? 14 : 15, lineHeight: whenOnHeader ? 1.2 : "normal", marginBottom: 10 }}>
                    {removePattern(course.name)}
                  </Typography.Title>

                  {course.firstCourseDay && (
                    <Typography.Title level={5} style={{ color: textColor, marginBottom: 0, marginTop: 0 }}>
                      Od {course.firstCourseDay.format("ll")}
                    </Typography.Title>
                  )}
                </Flex>
              ) : (
                <>
                  <Typography.Title
                    level={3}
                    style={{ color: textColor, fontSize: whenOnHeader ? 14 : 15, lineHeight: whenOnHeader ? 1.2 : "normal", marginBottom: 0 }}>
                    {removePattern(course.name)}
                  </Typography.Title>

                  {!whenOnHeader && dateAtHeader && course.firstCourseDay && (
                    <Typography.Title level={5} style={{ color: textColor, marginBottom: 0, marginTop: 0 }}>
                      Od {course.firstCourseDay.format("ll")}
                    </Typography.Title>
                  )}

                  {whenOnHeader && (
                    <Typography.Title level={5} style={{ color: textColor, fontSize: 11, lineHeight: 1.2, marginBottom: 0, marginTop: 0 }}>
                      {removePattern(course.when.toString())}
                    </Typography.Title>
                  )}
                </>
              )}
            </TopSpaceText>

            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                ${course.isOpen ? "" : grayScaleImageStyle}
              `}>
              {course.image && course.image.url && (
                <Image alt={course.image.alt || "course-image-url"} src={course.image.url} layout="fill" objectFit="cover" />
              )}
            </div>
          </TopSpace>
        </Link>

        {!simpleLayout && (
          <BottomSpace theme={theme}>
            {informationOnly ? (
              <Typography.Paragraph style={paragraphStyle} ellipsis={course.subCourse ? { ...ellipsis, rows: 4 } : ellipsis}>
                {course.excerptAsText}
              </Typography.Paragraph>
            ) : (
              <>
                <Rate
                  disabled
                  defaultValue={course.rate}
                  style={{ fontSize: 14 }}
                  css={css`
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 10px 10px 0 0;

                    ${md} {
                      display: none;
                    }
                  `}
                />
                <Space direction="vertical">
                  <div>
                    <H4 level={4} style={h4Style}>
                      Kde?
                    </H4>
                    <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                      {course.where}
                    </Typography.Paragraph>
                  </div>

                  {course.when && (
                    <div>
                      <H4 level={4} style={h4Style}>
                        Kdy?
                      </H4>
                      <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                        {course.when}
                      </Typography.Paragraph>
                    </div>
                  )}

                  <div>
                    <H4 level={4} style={h4Style}>
                      Informace
                    </H4>
                    <Typography.Paragraph style={paragraphStyle} ellipsis={ellipsis}>
                      {course.excerptAsText}
                    </Typography.Paragraph>
                  </div>
                </Space>
              </>
            )}
            <FlexEnd>
              {course.isOpen ? (
                <Button
                  type="primary"
                  {...(theme === Theme.dark && {
                    style: {
                      backgroundColor: desaturate(0.3, themeColorPalette.colors["primary-color"]),
                      borderColor: desaturate(0.4, themeColorPalette.colors["primary-color"])
                    }
                  })}>
                  <Link href={course.registerUrl}>Více informací</Link>
                </Button>
              ) : (
                <Button type="primary">
                  <Link href={course.registerUrl}>Více informací</Link>
                </Button>
              )}
            </FlexEnd>
          </BottomSpace>
        )}

        {withSubCourse && course.subCourse && <SubCourseCard subCourse={course.subCourse} />}
      </CourseCardWrapper>
    </>
  )
}

export interface CourseCardType {
  course: MappedCourse
  technology: CourseTechnology
  simpleLayout?: boolean
  theme?: Theme
  informationOnly?: boolean
  whenOnHeader?: boolean
  withSubCourse?: boolean
  dateAtHeader?: boolean
}

export const CourseCardWrapper = styled.div<{ disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  background-color: white;

  ${(props: { disabled?: boolean; isBeforeRegistration?: boolean; isAfterRegistration?: boolean; theme?: Theme }) =>
    props.disabled || props.isBeforeRegistration || props.isAfterRegistration
      ? `
    pointer-events: none;
    filter: grayscale(1);
    opacity: 75%;
    
    &:before {
      content: "${props.isBeforeRegistration ? "Již brzy!" : ""}${props.isAfterRegistration ? "Již proběhlo." : ""}"
      position: absolute;
      transform: rotate(-45deg) translate(50%, 50%);
      top: 50%;
      left: 5%;
      color: #fff;
      z-index: 9;
      opacity: 50%;
      font-size: 50px;
      padding: 5px 14px 5px 14px;
      ${props.theme === Theme.dark ? `background-color: ${themeColorPalette.colors.dark.bgGray};` : `background-color: #000;`}
    }
  `
      : ""}

  ${md} {
    flex-direction: column;
    width: 100%;
  }
`

export const TopSpace = styled.div<{ simpleLayout: boolean; height?: string }>`
  position: relative;
  ${(props: { simpleLayout: boolean }) => (props.simpleLayout ? `height: 145px;` : `height: 175px;`)}
  ${(props: { height?: string }) =>
    props.height
      ? `
    height: ${props.height};
    
    h3.ant-typography {
      margin-top: 0;
    }
  `
      : ``}
  width: 100%;

  ${xl} {
    min-width: 135px;
    width: initial;
  }

  ${md} {
    min-width: 135px;
    width: initial;
    height: 145px;
  }

  ${sm} {
    min-width: 125px;
    width: initial;
  }
`

export const TopSpaceText = styled.div<{ color: string; bgOpacity: number }>`
  ${(props: { color: string; bgOpacity: number }) => `background-color: ${rgba(props.color, props.bgOpacity)};`}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px 14px 12px 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${md} {
    padding: 9px;
  }

  &:hover {
    ${(props: { color: string }) => `background-color: ${rgba(props.color, 0.8)};`}
  }
`

export const BottomSpace = styled.div<{ theme?: Theme }>`
  width: 100%;
  padding: 14px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  place-content: space-between;
  ${(props: { theme?: Theme }) =>
    props.theme === Theme.dark
      ? `
    background-color: ${themeColorPalette.colors.dark.bgGray};
    
    .ant-typography, h3.ant-typography {
      color: ${themeColorPalette.colors.dark.textWhite};  
    }
  `
      : `background-color: #fff;`}

  ${md} {
    padding: 9px;
  }
`

export const FlexEnd = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 9px;
`

const ellipsis = {
  rows: 5,
  symbol: "více",
  expandable: true
}

export const paragraphStyle = {
  fontSize: 12,
  marginBottom: 0
}

export const h4Style = {
  marginBottom: "0.25em"
}

export const grayScaleImageStyle = `
  img {
    filter: grayscale(100%);
  }
`

const Flex = styled.div`
  ${sm} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`
